/* Tabs container */
.r-tabs {
	position: relative;
}

/* Tab element */
.r-tabs .r-tabs-nav .r-tabs-tab {
	position: relative;
}

/* Tab anchor */
.r-tabs .r-tabs-nav .r-tabs-anchor {
	display: inline-block;
}

/* Disabled tab */
.r-tabs .r-tabs-nav .r-tabs-state-disabled {
	opacity: 0.5;
}

/* Active state tab anchor */
.r-tabs .r-tabs-nav .r-tabs-state-active .r-tabs-anchor {
	text-shadow: none;
}

/* Tab panel */
.r-tabs .r-tabs-panel {
}

/* Accordion anchor */
.r-tabs .r-tabs-accordion-title .r-tabs-anchor {
    display: block;
    padding: 0.7em 0.4em;
    box-shadow: inset 0 -2px #d1d3d2;
    color: #74777b;
}

/* Active accordion anchor */
.r-tabs .r-tabs-accordion-title.r-tabs-state-active .r-tabs-anchor {
    color: #ffb503;
    text-shadow: none;
    box-shadow: inset 0 -2px #ffb503;
}

/* Disabled accordion button */
.r-tabs .r-tabs-accordion-title.r-tabs-state-disabled {
	opacity: 0.5;
}

/*extra*/
.r-tabs-accordion-title.r-tabs-state-active {
    box-shadow: inset 0 -2px #ffb503;
    border-bottom: 2px #ffb503 solid;
}